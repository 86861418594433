<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('General Add-ons Lists') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <Filter
              @filter="fetchData"
            />
          </div>
          <div
            class="column is-narrow"
          >
            <Modal ref="modalGeneralAddon">
              <template #default="{hide}">
                <GeneralAddonForm
                  :value="selectedGeneralAddon"
                  @close="() => {
                    hide();
                    fetchData();
                  }"
                  @cancel="() => {
                    hide();
                  }"
                />
              </template>
              <template #handler="{show}">
                <a
                  class="ant-btn ant-btn-lg ant-btn-primary"
                  @click.prevent="() => {
                    show();
                    selectedGeneralAddon = undefined;
                  }"
                >
                  {{ $t('Add General Add-ons') }}
                </a>
              </template>
            </Modal>
          </div>
        </div>
        <a-table
          row-key="id"
          row-class-name="pointer"
          :custom-row="selectRow"
          :columns="columns"
          :data-source="lists"
          :loading="isFetching"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #adrLift="{ text }">
            {{ $filters.number(text) }} JPY
          </template>
          <template #createdAt="{ text }">
            {{ $filters.date(text, { format: $variables.genericDateTimeFormat }) }}
          </template>
          <template #updatedAt="{ text }">
            {{ $filters.date(text, { format: $variables.genericDateTimeFormat }) }}
          </template>
          <template #action="{ record }">
            <Modal>
              <template #default="{hide}">
                <DelGeneralAddon
                  :value="record"
                  @close="()=>{hide();fetchData();}"
                  @cancel="hide"
                />
              </template>
              <template #handler="{show}">
                <DeleteFilled
                  class="has-text-danger"
                  @click.stop.prevent="show"
                />
              </template>
            </Modal>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { DeleteFilled } from '@ant-design/icons-vue';
import Modal from '@/components/Modal';
import pagination from '@/components/mixins/pagination';
import DelGeneralAddon from '@/views/simulations/components/DelGeneralAddon';
import GeneralAddonForm from '@/views/simulations/components/GeneralAddonForm';
import Filter from '@/views/simulations/components/FilterAddon';

export default {
  name: 'GeneralAddons',
  components: {
    Modal,
    DelGeneralAddon,
    DeleteFilled,
    GeneralAddonForm,
    Filter,
  },
  mixins: [pagination],
  data() {
    return {
      colWidth: 0,
      lists: undefined,
      selectedGeneralAddon: undefined,
      isFetching: false,
      columns: [
        {
          title: 'Features',
          dataIndex: 'feature',
          key: 'feature',
          width: '200px',
        },
        {
          title: 'ADR Lift',
          dataIndex: 'adrLift',
          key: 'adrLift',
          slots: { customRender: 'adrLift' },
        },
        {
          title: '',
          key: 'spacing',
        },
        {
          title: 'Generated at',
          dataIndex: 'createdAt',
          key: 'createdAt',
          slots: { customRender: 'createdAt' },
        },
        {
          title: 'Updated at',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          slots: { customRender: 'updatedAt' },
        },
        {
          title: ' ',
          key: 'action',
          align: 'right',
          slots: { customRender: 'action' },
        },
      ],
    };
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.setWidth();
    window.addEventListener('resize', () => {
      this.setWidth();
    });
  },
  methods: {
    setWidth() {
      if (window.innerWidth > 1215) {
        this.colWidth = 500;
      } else {
        this.colWidth = 0;
      }
      const spaceIndex = this.columns.findIndex((el) => el.key === 'spacing');
      this.columns[spaceIndex].width = `${this.colWidth}px`;

      if (window.innerWidth < 550) {
        this.columns.forEach((col, idx) => {
          if (col.key !== 'spacing' && col.key !== 'action') {
            this.columns[idx].width = `${130}px`;
          }
        });
      } else {
        this.columns.forEach((col, idx) => {
          if (col.key !== 'spacing' && col.key !== 'action') {
            delete this.columns[idx].width;
          }
        });
      }
    },
    selectRow(record) {
      return {
        onClick: () => {
          this.selectedGeneralAddon = record;
          this.$refs.modalGeneralAddon.show();
        },
      };
    },
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    fetchData() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      return this.$store
        .dispatch('simulations/getGeneralAddonLists', this.$route.query)
        .then((resp) => {
          const { data, pagination } = resp;

          this.lists = data;
          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
