<template>
  <a-form
    ref="forms"
    :model="form"
    :rules="rules"
    class="columns is-mini-gap is-multiline general-addon-form"
  >
    <div class="column is-12">
      <h2 class="ant-modal-title has-text-primary has-text-weight-bold is-size-4">
        {{ title }}
      </h2>
    </div>
    <a-form-item
      class="column is-12"
      :label="$t('Feature')"
      name="feature"
    >
      <a-input
        ref="feature"
        v-model:value="form.feature"
        :size="size"
        type="text"
      />
    </a-form-item>
    <a-form-item
      class="column is-12"
      :label="$t('ADR lift')"
      name="adrLift"
    >
      <div class="is-flex">
        <a-input-number
          v-model:value="form.adrLift"
          min="0"
          style="width: 100%;"
          :size="size"
        />
        <span style="width: 70px; text-align: center;">JPY</span>
      </div>
    </a-form-item>
    <div
      class="column has-text-right is-12 m-t-4x"
    >
      <a-button
        :disabled="isSubmitting"
        :size="size"
        class="button-secondary-outline m-r-3x"
        style="min-width: 115px;"
        @click="resetFields"
      >
        {{ $t('Cancel') }}
      </a-button>
      <a-button
        :loading="isSubmitting"
        :size="size"
        class="m-r-3x"
        style="min-width: 115px;"
        type="primary"
        @click="handleSubmit"
      >
        {{ $t('Save') }}
      </a-button>
    </div>
  </a-form>
</template>

<script>
export default {
  name: 'GeneralAddonForm',
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['cancel', 'close'],
  data() {
    return {
      size: 'large',
      isSubmitting: false,
      form: {
        feature: undefined,
        adrLift: undefined,
      },
      rules: {
        feature: [
          {
            required: true,
            message: this.$t('Feature is required'),
            trigger: ['change', 'blur'],
          },
        ],
        adrLift: [
          {
            required: true,
            type: 'number',
            message: this.$t('ADR Lift is required'),
            trigger: ['change', 'blur'],
          },
        ],
      },
    };
  },
  computed: {
    title() {
      return this.value && this.value.id
        ? this.$t('Edit general add-ons')
        : this.$t('Add general add-ons');
    },
  },
  watch: {
    value: {
      handler(nv) {
        if (nv) {
          this.form.feature = nv.feature || undefined;
          this.form.adrLift = nv.adrLift || undefined;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.feature.focus();
    });
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.forms.validate();
        this.isSubmitting = true;
        if (this.value && this.value.id) {
          const payload = { id: this.value.id, ...this.form };
          await this.$store.dispatch('simulations/updateGeneralAddon', payload);
        } else {
          const payload = { ...this.form };
          await this.$store.dispatch('simulations/addGeneralAddon', payload);
        }

        this.isSubmitting = false;
        this.$emit('close');
        this.resetFields();
      } catch (error) {
        this.isSubmitting = false;
      }
    },
    resetFields() {
      this.$refs.forms.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.general-addon-form::v-deep {
  .ant-input-number-handler-wrap {
      display: none;
    }

  .ant-input-number-input {
    text-align: right;
  }
}
</style>
